<template>
  <v-row>
    <v-col>
      <v-card class="mt-2">
        <v-row class="ma-0 pa-0">
          <v-col>
            <v-btn
              class="mt-2"
              color="primary"
              style="margin-bottom: 0;padding-bottom: 0"
              @click="editContractor(null)"
            >
              + DODAJ KONTRAHENTA
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            md="3"
            style="margin-bottom: 0;padding-bottom: 0"
          >
            <v-text-field
              v-model="search"
              :prepend-inner-icon="icons.mdiMagnify"
              class="mt-2"
              clearable
              dense
              outlined
              placeholder="Wyszukaj..."
              style="margin-bottom: 0;padding-bottom: 0"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        fixed-header
        item-key="id"
        :items="contractorsList"
        :headers="helpers.contractorHeaders"
        no-results-text="Nie znaleziono kontahentów"
        class="table-rounded"
        hide-default-footer
        height="65vh"
        no-data-text="Nie znaleziono kontahentów"
      >
        <template #[`item.actions`]="{item}">
          <v-btn
            :class="item.actions"
            icon
            @click="editContractor(item.id)"
          >
            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiClose,
  mdiDotsVertical, mdiExportVariant, mdiEye,
  mdiFileCheckOutline, mdiLaptop,
  mdiMagnify,
  mdiPencilOutline,
  mdiSquareEditOutline
} from '@mdi/js'
import helpers from '@/utils/helpers'

export default {
  name: "Contractors",
  data() {
    return {
      helpers,
      icons: {
        mdiFileCheckOutline,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiLaptop,
        mdiEye,
        mdiClose
      },
      contractorsList: [
        {id: 1, sellerName: "JanuszeX", NIP: "987654321"}
      ],
      search: ''
    }
  },
  mounted() {
    this.refreshDocuments()
  },
  methods: {
    editContractor(id) {
      if (id) {
        this.$router.push('/contractors/edit/' + id)
      } else {
        this.$router.push('/contractors/edit/new')
      }
    }

  }
}
</script>

<style scoped>

</style>
